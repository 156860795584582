$(document).on "turbolinks:load", ->
  close_open_event_form()
  $('#close_event_comment_form').click ->
    $('#add_new_event_comment').slideUp()
  $('#show_event_comment_form').click ->
    $('#add_new_event_comment').slideDown()

window.close_open_event_form = ->
  $('#divSideBarWrapper #add_new_event_link').click ->
    $('form#new_event').trigger('reset')
    $('#add_new_event').slideDown()
  $('#divSideBarWrapper #close_new_event_form').click ->
    $('#add_new_event').slideUp()
  $('#show_event_category_form').click ->
    $('#add_new_event_category').slideDown()
  $('#close_event_category_form').click ->
    $('#add_new_event_category').slideUp()
