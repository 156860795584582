require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

require.context('../images', true, /\.(gif|jpg|png|svg)$/i)

import '../stylesheets/application.scss'
import './base'
import './hello_coffee'
import './all_contacts'
import './events'
import './roles'
import './positions'
import './users'
