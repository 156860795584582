$(document).on "turbolinks:load", ->
  $('#show_new_task_form').click ->
    $('#add_new_task').slideDown()
  $('#hide_new_task_form').click ->
    $('#add_new_task').slideUp()

  $('#link_to_note').click ->
    $('#add_new_phone_number').hide()
    $('#add_new_postal_address').hide()
    $('#add_new_email_address').hide()
    $('#add_new_contact_note').slideDown()
  $('#link_close_contact_note_form').click ->
    $('#add_new_contact_note').slideUp()
  $('#link_to_postal_address').click ->
    $('#add_new_phone_number').hide()
    $('#add_new_email_address').hide()
    $('#add_new_contact_note').hide()
    $('#add_new_postal_address').slideDown()
  $('#link_close_postal_address_form').click ->
    $('#add_new_postal_address').slideUp()
  $('#link_to_phone_number').click ->
    $('#add_new_postal_address').hide()
    $('#add_new_email_address').hide()
    $('#add_new_contact_note').hide()
    $('#add_new_phone_number').slideDown()
  $('#link_close_phone_number_form').click ->
    $('#add_new_phone_number').slideUp()
  $('#link_to_email_address').click ->
    $('#add_new_postal_address').hide()
    $('#add_new_phone_number').hide()
    $('#add_new_contact_note').hide()
    $('#add_new_email_address').slideDown()
  $('#link_close_email_address_form').click ->
    $('#add_new_email_address').slideUp()
  $('#link_to_note_second').click ->
    $('#add_new_contact_note').slideDown()

  $('#close_event_list').click ->
    $('#list_of_all_events').slideUp()
    $('#add_event_to_person').slideUp()
  $('#open_event_list').click ->
    $('#list_of_all_events').slideDown()
