$(document).on "turbolinks:load", ->
  window.show_close_position_new_role_form()

window.show_close_position_new_role_form = ->
  $('#close_new_position_form').click ->
    $('#add_new_position').slideUp()
  $('#show_new_position_form').click ->
    $('#add_new_position').slideDown()
  $('#show_list_positions').click ->
    $('#list_of_all_positions').slideDown()
  $('#close_list_positions').click ->
    $('#list_of_all_positions').slideUp()
    $('#add_role_to_person').slideUp()
  $('.position-link').click ->
    shortTitle = $(this).data('title')
    id = $(this).data('id')
    $('#position_title_header').html(shortTitle)
    $('#contacts_position_position_id').val(id)
    $('#add_role_to_person').slideDown()
