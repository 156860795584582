window.sendContactForm = function (action, controller) {
  controller = controller || 'all_contacts'
  var form = $('#select_contacts_form');
  form.attr("action", '/' + controller + '/' + action);
  //Remove ### when working on the mass email action
  if (action == 'mass_email_selected###'){
    form.request();
  }
  else {
    form.submit();
  };
}



window.showQuickInfo = function (contact_id) {
  new Ajax.Request('/all_contacts/show_quick_info/' + contact_id,
  {asynchronous:true,
    evalScripts:true,
    onComplete:function(request){
        new Effect.Fade("quick_info_load_" + contact_id,{duration:0.6});
    },
    onLoading:function(request){
      Element.show('quick_info_load_' + contact_id)
    }
  });
}
